<template>
  <div id="Login" class="login fullheight" :class="{ 'c-dark-theme': $store.state.darkMode }">
    <CContainer fluid class="fullheight"> 
      <CRow class="align-start align-md-center fullheight">
        <CCol cols="12" md="6" class="d-none d-md-flex login__image justify-end"> 
          <img class="fullwidth" svg-inline src="/img/login.svg" alt="Ingresar">
        </CCol>
        <CCol cols="12" md="6" class="my-4 my-sm-8 my-md-0" style="margin-top: 200px !important">
          <div class="login__container">
            <div class="login__inner">
              <div class="login__image">
                <img src="/img/logotipo.png" alt="Logo Oscuro" style="width: 280px; margin:auto;"> 
              </div>
              <div class="login__header pt-5">
                  <h4>Inicia Sesión</h4>
              </div>
              <div class="login__subscribe d-flex justify-space-between align-center pb-8">
                  <p class="text-subtitle-2 ma-0">¿Aún no tienes tu cuenta?</p>
                  <!-- <a href="https://somosorderly.com/orderly-facturador-gratuito-pruebalo-gratis" class="custom-transform-class text-none font-weight-regular px-1">Regístrate aquí.</a> -->
                  <a href="/#/signup" class="custom-transform-class text-none font-weight-regular px-1">Regístrate aquí.</a>
              </div>
              <div class="login__form">
                <CRow>
                  <CCol sm="12">
                    <CInput
                      v-model="email"
                      label="Correo Electrónico"
                      placeholder=""
                    />
                  </CCol>
                </CRow>
                <CRow>
                  <CCol sm="12">
                    <CInput
                      v-model="password"
                      label="Contraseña"
                      type="password"
                      placeholder=""
                    />
                  </CCol>
                </CRow>
                <CRow>
                  <CCol sm="12">
                    <CButton type="button" color="primary" style="width: 100%" @click="login">INICIAR SESIÓN</CButton>
                  </CCol>
                </CRow>
              </div>
              <div class="login__actions d-flex justify-space-between py-2">
                  <div class="login__remind" style="font-size: 12px;">
                    <CInputCheckbox
                        label="Recordarme"
                        v-model="remind"
                        :checked="remind"
                    />
                  </div>
                  <div class="login__recovery-password" style="width: 100%">
                      <a href="/#/forget" class="font-weight-regular px-1" style="font-size: 12px; float: right !important;">¿Olvidaste tu contraseña?</a>
                  </div>
              </div>
            </div>
          </div>
        </CCol>
      </CRow>
    </CContainer>
    <loading :active="visible" :can-cancel="true"></loading>
    <CToaster placement="top-end">
      <template v-for="(toast, key) in toasts">
        <CToast :color="toast.color" v-bind="toast" :key="'toast' + key" :show="true">
          {{toast.content}}
        </CToast>
      </template>
    </CToaster>
  </div>
</template>
<script>
import router from '../router/index'
import login from '../services/login';
import account_ws from '../services/account';
import Vue from 'vue';
import store from '../store';
import Loading from 'vue-loading-overlay/dist/vue-loading.js';;
import 'vue-loading-overlay/dist/vue-loading.css';
import axios from 'axios'

const delay = ms => new Promise(res => setTimeout(res, ms));

export default {
  name: 'Login',
  components: {
    Loading
  },
  data: function () {
      return {
          email: '',
          password: '',
          remind: false
      }
  },
  mounted: async function() {
    this.loading();
    let divScripts = document.getElementById('pipedrive');

    let newScript = document.createElement('script');
    newScript.src = 'https://leadbooster-chat.pipedrive.com/assets/loader.js';

    divScripts.appendChild(newScript);

    window.pipedriveLeadboosterConfig = {base: 'leadbooster-chat.pipedrive.com',companyId: 1381454,playbookUuid: '7ecada02-f0be-4be6-999a-84b8d3d2c076',version: 2};(function () {var w = window;if (w.LeadBooster) {console.warn('LeadBooster already exists');} else {w.LeadBooster = {q: [],on: function (n, h) {this.q.push({ t: 'o', n: n, h: h });},trigger: function (n) {this.q.push({ t: 't', n: n });},};}})();
    this.loaded();       
  },
  methods: {
    login: async function(){
      this.loading();
      let params = {
        email: this.email,
        password: this.password,
        device_name: navigator.userAgent
      };

      let response = await login.login(params);

      if(response.type == "success"){
        let data = response.data;

        localStorage.setItem("token", data.access_token);

        axios.defaults.headers.common['access-token'] = data.access_token;
        axios.defaults.headers.common['contributor'] = data.last_contributor;
        
        let analytics = await account_ws.getOnboarding(); 

        if(analytics.type == "success"){
          if(analytics.data === true){
            this.loaded();
            window.location.href = "/#/welcome"; return;
          }
        }

        if(data.administrator == "1" || data.administrator == "71"){
          this.loaded();
          window.location.href = "/#/accounts";
          
          window.location.reload();
          return;
        }

        store.commit('setPermissions', data.permissions);

        if(data.permissions.dashboard === undefined || data.permissions.dashboard == 1){
          this.loaded();
          window.location.href = "/#/dashboard";
        }
        else{
          this.loaded();
          window.location.href = "/#/invoices";
        }

        window.location.reload();
      }
      else{
        this.loaded();        
        this.showToast("error",response.message);
        return false;
      }
      this.loaded();
    },
    showToast(type, message){
      store.commit('toast', {color: type, message: message});
    },
    loading(){
      store.commit('loading');
    },
    loaded(){
      store.commit('loaded');
    }
  },
  computed: {      
    visible() {
      return store.state.isLoading;
    },
    toasts () {
      return store.state.toasts;
    },
  },  
}
</script>

<style scoped>
  .fade-enter-active,
  .fade-leave-active {
    transition: opacity 0.3s;
  }
  .fade-enter,
  .fade-leave-to {
    opacity: 0;
  }
</style>
